import config from '../../../../app.config.json';
import { ContentHubArticleCategory } from '../types/strapi/overrides';

export const GUIDES_FEATURE = config?.guidesFeature != null ? config.guidesFeature === 'true' : true;
export const NEWS_FEATURE = config?.newsFeature != null ? config.newsFeature === 'true' : true;

export const GUIDE_CATEGORIES: ContentHubArticleCategory[] = ['buying', 'renting', 'selling', 'students'];
export const NEWS_CATEGORIES: ContentHubArticleCategory[] = ['news-and-analysis'];

export const DEFAULT_META_DESCRIPTION =
  'Discover Useful Guides On Buying, Selling, Renting, Moving, Investing And Mortgages, As Well As Northern Ireland Housing Market Updates.';
