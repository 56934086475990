/* eslint-disable no-undef */
import { Dispatch } from 'redux';
import appConfig from '../../../../app.config.json';
import logging from '../services/logging';
import { NetworkContext } from '../types/network';
import { getAuthHeaders, setClientToken } from '../utils/auth';
import getUserAgent from '../utils/getUserAgent';
import isReactNative from '../utils/isReactNative';
import logout from '../utils/logout';
import serializeObject, { SerializableObject } from '../utils/serializeObject';
import { getItem } from './storage.secure';

export interface Config {
  url: string;
  method?: string;
  data?: object;
  formData?: FormData;
  headers?: { [key: string]: string };
  params?: SerializableObject;
}

export const API_URL =
  typeof document === 'undefined' || process?.env?.JEST_WORKER_ID
    ? appConfig.apiUrl
    : `${window.location.origin}/_wapi`;

export const urlFromConfig = (config: Config) => {
  if (config.params) {
    const urlParams = serializeObject(config.params);

    if (urlParams) {
      const connector = config.url.includes('?') ? '&' : '?';
      return `${config.url}${connector}${urlParams}`;
    }
  }

  return config.url;
};

const getBody = (config: Config) => {
  if (config.data) {
    return JSON.stringify(config.data);
  }

  if (config.formData && !process?.env?.JEST_WORKER_ID) {
    return config.formData;
  }

  return undefined;
};

const apiRequest = async <T = any>(
  config: Config,
  dispatch: Dispatch | undefined,
  ctx?: NetworkContext,
  retried?: boolean,
): Promise<Response & { data: T }> => {
  const url = urlFromConfig(config);
  const authHeaders = await getAuthHeaders(url, ctx);
  const userAgent = getUserAgent(ctx);
  const contentType: HeadersInit = config.data ? { 'Content-Type': 'application/json' } : {};

  logging.logCrumb({
    message: 'request',
    metaData: {
      url,
      method: config.method,
    },
  });

  const result = await fetch(url, {
    method: config.method || 'GET',
    headers: {
      ...authHeaders,
      ...userAgent,
      ...contentType,
      ...config.headers,
    },
    credentials: isReactNative() ? undefined : 'include',
    body: getBody(config),
  });

  let data = null;

  try {
    data = await result.json();
  } catch {
    // silence error for empty responses
  }

  if (result.ok) {
    const xClientToken = result.headers.get('x-client-token');

    if (xClientToken) {
      const token = await getItem('token', ctx);
      await setClientToken(xClientToken, token?.emailHashes, dispatch, ctx);
    }

    return {
      ...result,
      headers: result.headers,
      data,
    };
  }

  logging.logCrumb({
    message: `API error ${result.status} on ${url}`,
    metaData: {
      result,
      data,
    },
  });

  // cf-mitigated header indicates the request was blocked by cloudflare
  if (result.headers.get('cf-mitigated') !== 'challenge') {
    // Mortgage product errors ignored. Mortgage endpoint routinely errors overnight due to 3rd party issue
    if (!url.includes('/mortgages/mortgage/product')) {
      logging.logError(new Error(`API error ${result.status} on ${url}`));
    }

    if ([403].includes(result.status)) {
      await logout(dispatch, ctx);
    }

    if ([401, 429, 503, 504].includes(result.status) && !retried) {
      return apiRequest(config, dispatch, ctx, true);
    }
  }

  // eslint-disable-next-line no-throw-literal
  throw { response: result, data };
};

export default apiRequest;
